import { DateTypeComponent } from '@core/builders/input-types/date-type/date-type.component';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ApiModule } from '@capturum/api';
import { AuthModule } from '@capturum/auth';
import { CapturumFormRendererModule, inputTypeComponents } from '@capturum/builders/form-renderer';
import {
  BaseDataModule,
  CapturumHttpCodeInterceptor,
  CompleteModule,
  ErrorMessageInterceptor,
} from '@capturum/complete';
import { LoginModule } from '@capturum/login';
import { CapturumButtonModule } from '@capturum/ui/button';
import { CapturumDropdownModule } from '@capturum/ui/dropdown';
import { HorizontalNavbarModule } from '@capturum/ui/horizontal-navbar';
import { CapturumInputModule } from '@capturum/ui/input';
import { CapturumPasswordModule } from '@capturum/ui/password';
import { HiddenWrapperComponent } from '@core/builders/wrappers/hidden-wrapper.component';
import { MetaValueWrapperComponent } from '@core/builders/wrappers/meta-value-wrapper/meta-value-wrapper.component';
import { BasicLayoutComponent } from '@core/components/basic-layout/basic-layout.component';
import { HeaderComponent } from '@core/components/header/header.component';
import { NavigationBarComponent } from '@core/components/navigation-bar/navigation-bar.component';
import { UserMenuComponent } from '@core/components/user-menu/user-menu.component';
import { ProjectMetaInterceptor } from '@core/interceptors/project-meta.interceptor';
import { WarningInterceptor } from '@core/interceptors/warning.interceptor';
import { environment } from '@environments/environment';
import { FormlyModule } from '@ngx-formly/core';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '@shared/shared.module';
import { NgxPermissionsModule, NgxPermissionsService } from 'ngx-permissions';
import { CalendarModule } from 'primeng/calendar';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ToastModule } from 'primeng/toast';

import { ProjectHourModule } from '../features/project-hour/project-hour.module';
import { AgentQuestionnaireModule } from './../features/agent-questionnaire/agent-questionnaire.module';
import { DataCollectiefWidgetComponent } from './builders/column-types/data-collectief-widget/data-collectief-widget.component';
import { IsActiveWidgetComponent } from './builders/column-types/is-active-widget/is-active-widget.component';
import { TimeDifferenceWidgetComponent } from './builders/column-types/time-difference-widget/time-difference-widget.component';
import { UserStatusWidgetComponent } from './builders/column-types/user-status-widget/user-status-widget.component';
import { UtcDateComponent } from './builders/column-types/utc-date/utc-date.component';
import { AddressesTypeComponent } from './builders/input-types/addresses-type/addresses-type.component';
import { ContactsTypeComponent } from './builders/input-types/contacts-type/contacts-type.component';
import { HiddenTypeComponent } from './builders/input-types/hidden-type/hidden-type.component';
import { MetaTextInputComponent } from './builders/input-types/meta-text-input/meta-text-input.component';
import { MetaValueOptionTypeComponent } from './builders/input-types/meta-value-option-type/meta-value-option-type.component';
import { PasswordTypeComponent } from './builders/input-types/password-type/password-type.component';
import { QuestionPlaceholderComponent } from './builders/input-types/question-placeholder/question-placeholder.component';
import { TimePickerComponent } from './builders/input-types/time-picker/time-picker.component';
import { UrlTypeComponent } from './builders/input-types/url-type/url-type.component';
import { CallbackRepeatComponent } from './builders/input-types/callback-repeat/callback-repeat.component';
import { NoLabelWrapperComponent } from '@core/builders/wrappers/no-label-wrapper.component';
import { MutationApprovalWidgetComponent } from './builders/column-types/mutation-approval-widget/mutation-approval-widget.component';
import { CapturumNotificationBadgeModule } from '@capturum/ui/notification-badge';
import { CalendarFilterComponent } from './builders/filters/calendar-filter/calendar-filter.component';
import { ImportRequestTypeComponent } from './builders/column-types/import-request-type/import-request-type.component';
import { TooltipModule } from 'primeng/tooltip';
import { ActionRequiredBadgeWidgetComponent } from './builders/column-types/action-required-badge-widget/action-required-badge-widget.component';
import { LeadAgendaItemComponent } from '@core/builders/input-types/lead-agenda-item/lead-agenda-item.component';
import { DownloadableFileTypeComponent } from './builders/input-types/downloadablefile-type/downloadablefile-type.component';
import { InputFilterComponent } from '@core/builders/filters/input-filter/input-filter.component';
import {RefreshTokenInterceptor} from "@core/interceptors/refresh-token.interceptor";
function onAuthError(): void {
  localStorage.clear();
  window.location.href = `auth/login`;
}

@NgModule({
  declarations: [
    HeaderComponent,
    NavigationBarComponent,
    UserMenuComponent,
    BasicLayoutComponent,
    UserStatusWidgetComponent,
    PasswordTypeComponent,
    HiddenTypeComponent,
    HiddenWrapperComponent,
    UrlTypeComponent,
    AddressesTypeComponent,
    ContactsTypeComponent,
    MetaTextInputComponent,
    IsActiveWidgetComponent,
    UtcDateComponent,
    DataCollectiefWidgetComponent,
    TimePickerComponent,
    TimeDifferenceWidgetComponent,
    QuestionPlaceholderComponent,
    MetaValueOptionTypeComponent,
    MetaValueWrapperComponent,
    CallbackRepeatComponent,
    NoLabelWrapperComponent,
    DateTypeComponent,
    MutationApprovalWidgetComponent,
    CalendarFilterComponent,
    ImportRequestTypeComponent,
    ActionRequiredBadgeWidgetComponent,
    LeadAgendaItemComponent,
    DownloadableFileTypeComponent,
    InputFilterComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    HorizontalNavbarModule,
    RouterModule,
    OverlayPanelModule,
    FormlyModule.forRoot({
      types: inputTypeComponents,
    }),
    NgxPermissionsModule.forRoot(),
    AuthModule.forRoot({
      baseUrl: environment.baseUrl,
      production: environment.production,
      authRejectionRoute: 'auth/login',
      userIdleConfig: {
        idleDuration: environment.idleDuration,
        timeout: environment.timeoutDuration,
      },
      authRefreshToken: {
        useRefreshToken: true,
        offsetSeconds: 3000,
        intervalSeconds: 60,
      },
    }),
    ApiModule.forRoot({
      baseUrl: environment.baseUrl,
      production: environment.production,
      onAuthError,
    }),
    LoginModule.forRoot({
      environment,
      productName: 'Inloggen',
      redirectAfterLogin: 'admin/config',
      logoImageUrl: './assets/images/logo.png',
      backgroundImageUrl: './assets/images/login_bg.png',
    }),
    CompleteModule.forRoot(
      {
        indexedDbModels: [],
        databaseName: environment.databaseName,
        version: 1,
        userSpecificDb: false,
        environment: environment,
      },
      NgxPermissionsService,
      NgxPermissionsModule
    ),
    SharedModule,
    CapturumPasswordModule,
    CapturumDropdownModule,
    ReactiveFormsModule,
    CapturumButtonModule,
    CapturumFormRendererModule,
    CapturumInputModule,
    BaseDataModule,
    CalendarModule,
    ProjectHourModule,
    AgentQuestionnaireModule,
    ToastModule,
    CalendarModule,
    CapturumNotificationBadgeModule,
    TooltipModule,
  ],
  providers: [
    CapturumHttpCodeInterceptor,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RefreshTokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CapturumHttpCodeInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorMessageInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ProjectMetaInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: WarningInterceptor,
      multi: true,
    },
  ],
  exports: [HeaderComponent, NavigationBarComponent, UserMenuComponent, BasicLayoutComponent],
})
export class CoreModule {}
