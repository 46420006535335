import { DestroyBase } from '@capturum/shared';
import { ChangeDetectionStrategy, Component, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { LeadsMap } from '@features/lead/interfaces/lead.interface';
import { LeadPin } from '@features/project/interfaces/lead-pin.interface';
import MarkerOptions = google.maps.MarkerOptions;

@Component({
  selector: 'app-leads-map',
  templateUrl: './leads-map.component.html',
  styleUrls: ['./leads-map.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectLeadsMapComponent extends DestroyBase {
  public defaultMapCenter = { lat: 52.0263009, lng: 5.5544309 };

  public markerOptions: MarkerOptions = {
    optimized: true,
  };

  @Input()
  public options: google.maps.MapOptions = {
    center: this.defaultMapCenter,
    zoom: 10,
    disableDefaultUI: true,
    zoomControl: true,
    fullscreenControl: true,
    gestureHandling: 'cooperative',
  };

  public markerClustererImagePath =
    'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m';

  @ViewChild('gmap')
  public gmap: GoogleMap;

  @ViewChild(MapInfoWindow)
  public infoWindow: MapInfoWindow;

  public circleCenter: google.maps.LatLngLiteral;
  public circleRadius: number;
  public mapCenter: google.maps.LatLngLiteral;
  public mapCenterSubject: Subject<google.maps.LatLngLiteral> = new Subject<google.maps.LatLngLiteral>();
  public circleOptions: google.maps.CircleOptions = {
    fillColor: '#009fec',
    fillOpacity: 0.1,
    strokeColor: '#009fec',
    strokeWeight: 1,
  };

  public infoWindowContext: { [key: string]: any };
  public infoWindowOptions: google.maps.InfoWindowOptions = {
    minWidth: 300,
    maxWidth: 400,
  };

  private _data: LeadsMap;
  private _colorMap: Map<string, string> = new Map()
    .set('md_leads', '#0971f0')
    .set('customer', '#ca4751')
    .set('others', '#1a806a');

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
  ) {
    super();
  }

  public get data(): LeadsMap {
    return this._data;
  }

  @Input()
  public set data(value: LeadsMap) {
    if (value) {
      this._data = this.enrichDataWithColor(value);

      const radiusCenter = value.lon ? { lng: value.lon, lat: value.lat } : null;

      this.setRadiusCenter(radiusCenter);
      this.fitMarkers();
    }
  }

  public goToDetailPage(lead: LeadPin): void {
    this.router.navigate([lead.lead_id], { relativeTo: this.route });
  }

  public openInfoWindow(marker: MapMarker, index: number): void {
    this.infoWindowContext = {
      $implicit: this.data?.leads[index],
    };

    this.infoWindow.open(marker);
  }

  private fitMarkers(): void {
    if (!this.gmap?.googleMap)
        return;

    if (this.data.leads.length > 0) {
      const bounds = new google.maps.LatLngBounds();
      this.data.leads.forEach(lead => {
        bounds.extend(new google.maps.LatLng(lead.lat, lead.lon));
      });
      this.gmap.googleMap.fitBounds(bounds);

      this.gmap.googleMap.setZoom(
        Math.min(this.gmap.googleMap?.getZoom(), 14)
      );
    }
  }

  private setRadiusCenter(center?: { lng: number; lat: number }): void {
    this.circleCenter = center;
    const radius = this.data?.filters.find((filter) => {
      return filter.field === 'radius';
    })?.value;

    this.circleRadius = radius ? parseInt(radius, 10) : null;

    if (this.data.leads.length === 0) {
      this.gmap.googleMap?.setCenter(this.circleCenter);
    }
  }

  private enrichDataWithColor(mapData: LeadsMap): LeadsMap {
    return {
      ...mapData,
      leads: mapData.leads.map((lead) => {
        let color = this._colorMap.get(lead.source_value?.toLowerCase());

        if (!color) {
          color = this._colorMap.get('others');
        }

        return {
          ...lead,
          lon: +lead.lon,
          lat: +lead.lat,
          color,
        };
      }),
    };
  }
}
