import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { ApiHttpService, ApiService, ListOptions } from '@capturum/api';
import { responseData, toMapItems } from '@capturum/builders/core';
import { BatchStatusService, FinishedBatchStatus, User } from '@capturum/complete';
import { FilterMatchMode, MapItem } from '@capturum/ui/api';
import { Lead } from '@features/lead/interfaces/lead.interface';
import { GripMeter } from '@features/project/interfaces/grip-meter.interface';
import { PipelineData, PipelineTotals } from '@features/project/interfaces/pipeline.interface';
import { ProjectLogNote } from '@features/project/interfaces/project-log-note.interface';
import { ProjectLog } from '@features/project/interfaces/project-log.interface';
import { TranslateService } from '@ngx-translate/core';
import { NgxPermissionsService } from 'ngx-permissions';
import { catchError, Observable, of, switchMap } from 'rxjs';
import { map } from 'rxjs/operators';
import { MetaKey } from '../../meta-key/interfaces/meta-key.interface';
import { LeadPin } from '../interfaces/lead-pin.interface';
import { Project } from '../interfaces/project.interface';
import {
  RealizedRoiResponse,
  RoiMassUpdateRequest,
  RoiResponse,
  ValidateMassUpdateRoiResponse,
} from '../interfaces/roi.interface';
import { XyzFormatedResult, XyzResponse, XyzTableRow } from '../interfaces/xyz.interface';
import { FullBusinessApiIndexResult, FullBusinessData } from '../interfaces/full-business.interface';
import { Fieldwork, FieldworkStatus } from '@core/interfaces/fieldwork.interface';
import { StakeholdersResponse } from '../interfaces/stakeholders.interface';
import { XyzService } from '../../xyz/service/xyz.service';

@Injectable({
  providedIn: 'root',
})
export class ProjectApiService extends ApiService<Project> {
  protected endpoint = 'project';

  constructor(
    apiHttp: ApiHttpService,
    private translateService: TranslateService,
    private batchStatusService: BatchStatusService,
    @Inject(LOCALE_ID)
    public locale: string,
    private ngxPermissionService: NgxPermissionsService,
    private xyzService: XyzService
  ) {
    super(apiHttp);
  }

  public getStakeholderData(id: string): Observable<StakeholdersResponse> {
    return this.apiHttp.get<{ data: StakeholdersResponse }>(`/${this.endpoint}/${id}/stakeholders`).pipe(responseData);
  }

  public getAccountManagersList(id: string): Observable<MapItem[]> {
    return this.apiHttp.get<{ data: User[] }>(`/${this.endpoint}/${id}/account_managers`).pipe(
      map((response) => {
        return response.data.map((user) => {
          return {
            value: user.id,
            label: user.name,
          };
        });
      })
    );
  }

  public getSalesManagerList(id: string): Observable<MapItem[]> {
    return this.apiHttp.get<{ data: User[] }>(`/${this.endpoint}/${id}/sales_managers`).pipe(
      map((response) => {
        return response.data.map((user) => {
          return {
            value: user.id,
            label: user.name,
          };
        });
      })
    );
  }

  public getAgentList(id: string): Observable<MapItem[]> {
    return this.apiHttp.get<{ data: User[] }>(`/${this.endpoint}/${id}/agents`).pipe(
      map((response) => {
        return response.data.map((user) => {
          return {
            value: user.id,
            label: user.name,
          };
        });
      })
    );
  }

  public getManagerList(id: string): Observable<MapItem[]> {
    return this.apiHttp.get<{ data: User[] }>(`/${this.endpoint}/${id}/managers`).pipe(
      map((response) => {
        return response.data.map((user) => {
          return {
            value: user.id,
            label: user.name,
          };
        });
      })
    );
  }

  public saveAccountManagers(projectId: string, accountManagerIds: string[]): Observable<Project> {
    return this.apiHttp
      .post<{ data: Project }>(`/${this.endpoint}/${projectId}/account_managers`, {
        account_manager_ids: accountManagerIds,
      })
      .pipe(responseData);
  }

  public saveSalesManagers(projectId: string, salesManagerIds: string[]): Observable<Project> {
    return this.apiHttp
      .post<{ data: Project }>(`/${this.endpoint}/${projectId}/sales_managers`, { sales_manager_ids: salesManagerIds })
      .pipe(responseData);
  }

  public saveUsers(projectId: string, userIds: string[]): Observable<Project> {
    return this.apiHttp
      .post<{ data: Project }>(`/${this.endpoint}/${projectId}/users`, { user_ids: userIds })
      .pipe(responseData);
  }

  public transferAccountManager(
    projectId: string,
    data: { account_manager_id: string; lead_ids: string[] }
  ): Observable<{ data: Project }> {
    return this.apiHttp.post<{ data: Project }>(`/${this.endpoint}/${projectId}/transfer_leads`, data);
  }

  public changeSourceForLeads(
    projectId: string,
    data: { lead_source_base_data_value_id: string; lead_ids: string[] }
  ): Observable<{ data: Project }> {
    return this.apiHttp.post<{ data: Project }>(`/${this.endpoint}/${projectId}/change_leads_source`, data);
  }

  public submitProjectFullBusiness(
    projectId: string,
    data: FullBusinessData,
    options?: ListOptions
  ): Observable<FullBusinessData> {
    return this.apiHttp
      .put(`/${this.endpoint}/${projectId}/full-business${this.getOptionsQuery(options)}`, data)
      .pipe(responseData);
  }

  public listMetaKeys(projectId: string): Observable<MetaKey[]> {
    const options = ApiService.generateQuery({ include: ['type'] });

    return this.apiHttp.get(`/${this.endpoint}/${projectId}/meta-keys${options}`).pipe(responseData);
  }

  public listSources(projectId: string): Observable<MapItem[]> {
    return this.apiHttp.get(`/${this.endpoint}/${projectId}/sources`).pipe(toMapItems);
  }

  public listCallCenterSources(projectId: string): Observable<MapItem[]> {
    return this.apiHttp.get(`/${this.endpoint}/${projectId}/call-center-sources`).pipe(toMapItems);
  }

  public listMetaValues(projectId: string, metaKeyId: string, onlyCallCenterLeads?: boolean): Observable<MapItem[]> {
    let options: ListOptions = {};

    if (onlyCallCenterLeads) {
      options = {
        parameters: [
          {
            field: 'onlyCallCenterLeads',
            value: true,
          },
        ],
      };
    }

    return this.apiHttp
      .get(`/${this.endpoint}/${projectId}/meta-key/${metaKeyId}${this.getOptionsQuery(options)}`)
      .pipe(toMapItems);
  }

  public getPlannedRoi(
    projectId: string,
    options: ListOptions,
    useCurrency: boolean
  ): Observable<Record<string, number | string | boolean>[]> {
    const filters = options?.filters.map((filter) => {
      if (filter.field === 'user_ids' && Array.isArray(filter.value)) {
        filter.value = filter.value.join(',');
      }

      return filter;
    });
    const apiOptions: ListOptions = { parameters: filters || null };

    return this.apiHttp.get(`/${this.endpoint}/${projectId}/roi/planned${this.getOptionsQuery(apiOptions)}`).pipe(
      responseData,
      map((roiData) => {
        const tableRowProperties = [
          { field: 'account_managers_number', highlight: true },
          { field: 'standard_leads_number' },
          { field: 'succession_percentage', underlined: true, percentage: true, decimal: true },
          { field: 'x1_number' },
          { field: 'y1_number' },
          { field: 'z1_number', underlined: true },
          { field: 'x1_target', permissions: ['project.roi.show-targets'], decimal: true },
          { field: 'y1_target', permissions: ['project.roi.show-targets'], decimal: true },
          { field: 'z1_target', permissions: ['project.roi.show-targets'], underlined: true, decimal: true },
          { field: 'average_z1_value', totalField: true, decimal: true },
          { field: 'leads_number', underlined: true, bold: true, highlight: true, totalField: true },
          { field: 'y2_number' },
        ];

        return this.mapToRoiTableRow(roiData, tableRowProperties, useCurrency);
      })
    );
  }

  public massUpdateRoi(body: RoiMassUpdateRequest): Observable<void> {
    return this.apiHttp.put(`/roi-month/mass-update`, body);
  }

  public validateMassUpdateRoi(body: RoiMassUpdateRequest): Observable<ValidateMassUpdateRoiResponse> {
    return this.apiHttp.post(`/roi-month/validate-mass-update`, body);
  }

  public getProjectFullBusiness(projectId: string, options?: ListOptions): Observable<FullBusinessApiIndexResult> {
    return this.apiHttp.get(`/${this.endpoint}/${projectId}/full-business${this.getOptionsQuery(options)}`);
  }

  public getPipelineDataByStatus(
    projectId: string,
    statusId: string,
    options: ListOptions
  ): Observable<PipelineData[]> {
    const filters = options?.parameters.map((filter) => {
      if (filter.field === 'source_ids' && Array.isArray(filter.value)) {
        filter.value = filter.value.join(',');
      }

      return filter;
    });
    const apiOptions: ListOptions = { parameters: filters || null };

    return this.apiHttp
      .get(`/${this.endpoint}/${projectId}/pipeline/${statusId}/leads${this.getOptionsQuery(apiOptions)}`)
      .pipe(responseData);
  }

  public getPipelineTotals(
    projectId: string,
    options: ListOptions
  ): Observable<{ totals: PipelineTotals[]; statuses: any[] }> {
    const filters = options?.parameters.map((filter) => {
      if ((filter.field === 'source_ids' || filter.field === 'user_ids') && Array.isArray(filter.value)) {
        filter.value = filter.value.join(',');
      }

      return filter;
    });
    const apiOptions: ListOptions = { parameters: filters || null };

    return this.apiHttp.get(`/${this.endpoint}/${projectId}/pipeline${this.getOptionsQuery(apiOptions)}`).pipe(
      catchError(() => {
        return of({ data: { statuses: [], totals: [] } });
      }),
      responseData
    );
  }

  public getGripMeterData(
    projectId: string,
    options: ListOptions
  ): Observable<{ rowData: GripMeter[]; totalRowData: GripMeter }> {
    let url = `/${this.endpoint}/${projectId}/grip-overview`;

    if (options) {
      url += this.getOptionsQuery(options);
    }

    return this.apiHttp.get(url).pipe(
      responseData,
      map((gripMeterData: GripMeter[]) => {
        return {
          rowData: [
            ...gripMeterData.filter((data) => {
              return data.account_manager_name;
            }),
          ],
          totalRowData: {
            ...gripMeterData.find((data) => {
              return !data.account_manager_name;
            }),
          },
        };
      })
    );
  }

  public getProjectLogData(projectId: string): Observable<ProjectLog[]> {
    const options: ListOptions = {
      include: ['createdByUser'],
    };

    return this.apiHttp
      .get<{ data: ProjectLog[] }>(`/${this.endpoint}/${projectId}/activity${this.getOptionsQuery(options)}`)
      .pipe(
        responseData,
        map((activities) => {
          return activities.sort((a, b) => {
            return (new Date(b.created_at) as any) - (new Date(a.created_at) as any);
          });
        })
      );
  }

  public addNewNote(projectId: string, note: ProjectLogNote): Observable<ProjectLog> {
    return this.apiHttp.post(`/${this.endpoint}/${projectId}/activity`, note);
  }

  public deleteNote(projectId: string, id: string): Observable<ProjectLog> {
    return this.apiHttp.delete(`/${this.endpoint}/${projectId}/activity/${id}`);
  }

  public importSelection(projectId: string, selectionId: string): Observable<void> {
    return this.apiHttp.post(`/${this.endpoint}/${projectId}/import-selection`, { selection_id: selectionId });
  }

  public getXyz(projectId: string, options: ListOptions): Observable<XyzFormatedResult> {
    const queryOptions = {
      ...options,
      parameters: options.parameters.map((parameter) => {
        if (parameter.field === 'source_ids' && Array.isArray(parameter?.value)) {
          parameter.value = parameter.value.join(',');
        }

        return parameter;
      }),
    };

    return this.apiHttp
      .get<{ data: XyzResponse }>(`/${this.endpoint}/${projectId}/xyz${this.getOptionsQuery(queryOptions)}`)
      .pipe(
        responseData,
        map((xyz) => {
          return this.xyzService.formatXyz(xyz);
        })
      );
  }

  public listFieldworkSources(projectId: string): Observable<MapItem[]> {
    return this.apiHttp.get(`/${this.endpoint}/${projectId}/fieldwork-sources`).pipe(toMapItems);
  }

  public getLeadPins(
    projectId: string,
    options?: ListOptions
  ): Observable<{ data: LeadPin[]; lat: number; lon: number }> {
    return this.apiHttp.get(`/lead/${this.endpoint}/${projectId}/lead-pins${this.getOptionsQuery(options)}`);
  }

  public realizedRoi(
    projectId: string,
    options: ListOptions,
    useCurrency: boolean
  ): Observable<Record<string, string | number | boolean>[]> {
    const filters = options?.filters.map((filter) => {
      if (filter.field === 'user_ids' && Array.isArray(filter.value)) {
        filter.value = filter.value.join(',');
      }

      return filter;
    });
    const apiOptions: ListOptions = { parameters: filters || null };

    return this.apiHttp
      .get<{ data: RealizedRoiResponse[] }>(
        `/${this.endpoint}/${projectId}/roi/realized${this.getOptionsQuery(apiOptions)}`
      )
      .pipe(
        map((response) => {
          return this.mapToRoiTableRow(
            response.data,
            [
              { field: 'standard_leads_count_difference' },
              { field: 'follow_up_difference', underlined: true, percentage: true },
              { field: 'x1_count_difference' },
              { field: 'y1_count_difference' },
              { field: 'z1_count_difference', underlined: true },
              { field: 'leads_count_difference', underlined: true, bold: true, highlight: true, totalField: true },
              { field: 'y2_count_difference' },
            ],
            useCurrency
          );
        })
      );
  }

  public downloadLetters(projectId: string, letterId: string): Observable<FinishedBatchStatus> {
    return this.apiHttp
      .get<{ data: { batch_id: string } }>(`/${this.endpoint}/${projectId}/letter/${letterId}/pdf`)
      .pipe(
        switchMap((response) => {
          return this.batchStatusService.getIsUpdatedBatch(
            response.data.batch_id,
            true,
            'market_discovery.letter.download.success',
            true
          );
        })
      );
  }

  public exportFullBusiness(projectId: string, options?: ListOptions): Observable<FinishedBatchStatus> {
    return this.apiHttp
      .get<{ data: { batch_id: string } }>(
        `/${this.endpoint}/${projectId}/full-business/export${this.getOptionsQuery(options)}`
      )
      .pipe(
        switchMap((response) => {
          return this.batchStatusService.getIsUpdatedBatch(
            response.data.batch_id,
            true,
            'market_discovery.project.full_business.export.success',
            true
          );
        })
      );
  }

  public exportFieldwork(projectId: string, options?: ListOptions): Observable<FinishedBatchStatus> {
    const apiOptionsQuery = this.transformFieldworkOptionsToQuery(options);
    return this.apiHttp
      .get<{ data: { batch_id: string } }>(
        `/${this.endpoint}/${projectId}/fieldwork/export${apiOptionsQuery}`
      )
      .pipe(
        switchMap((response) => {
          return this.batchStatusService.getIsUpdatedBatch(
            response.data.batch_id,
            true,
            'market_discovery.project.fieldwork.export.success',
            true
          );
        })
      );
  }

  public exportXyz(projectId: string, options?: ListOptions): Observable<FinishedBatchStatus> {
    return this.apiHttp
      .get<{ data: { batch_id: string } }>(`/${this.endpoint}/${projectId}/xyz-export${this.getOptionsQuery(options)}`)
      .pipe(
        switchMap((response) => {
          return this.batchStatusService.getIsUpdatedBatch(
            response.data.batch_id,
            true,
            'market_discovery.project.xyz.export.success',
            true
          );
        })
      );
  }

  public exportGripMeter(projectId: string, options?: ListOptions): Observable<FinishedBatchStatus> {
    return this.apiHttp
      .get<{ data: { batch_id: string } }>(
        `/${this.endpoint}/${projectId}/grip-overview/export${this.getOptionsQuery(options)}`
      )
      .pipe(
        switchMap((response) => {
          return this.batchStatusService.getIsUpdatedBatch(
            response.data.batch_id,
            true,
            'market_discovery.project.grip-meter.export.success',
            true
          );
        })
      );
  }

  public exportRoi(projectId: string, options?: ListOptions): Observable<FinishedBatchStatus> {
    return this.apiHttp
      .get<{ data: { batch_id: string } }>(`/${this.endpoint}/${projectId}/roi-export${this.getOptionsQuery(options)}`)
      .pipe(
        switchMap((response) => {
          return this.batchStatusService.getIsUpdatedBatch(
            response.data.batch_id,
            true,
            'market_discovery.roi.export.success',
            true
          );
        })
      );
  }

  public exportPipeline(projectId: string, options?: ListOptions): Observable<FinishedBatchStatus> {
    return this.apiHttp
      .get<{ data: { batch_id: string } }>(
        `/${this.endpoint}/${projectId}/pipeline/export${this.getOptionsQuery(options)}`
      )
      .pipe(
        switchMap((response) => {
          return this.batchStatusService.getIsUpdatedBatch(
            response.data.batch_id,
            true,
            'market_discovery.pipeline.export.success',
            true
          );
        })
      );
  }

  public exportLeads(projectId: string, options?: ListOptions): Observable<FinishedBatchStatus> {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    return this.apiHttp
      .post<{ data: { batch_id: string } }>(
        `/${this.endpoint}/${projectId}/leads/export${this.getOptionsQuery(options)}`,
        { timezone }
      )
      .pipe(
        switchMap((response) => {
          return this.batchStatusService.getIsUpdatedBatch(
            response.data.batch_id,
            true,
            'market_discovery.pdf-generated.success',
            true
          );
        })
      );
  }

  public getRoiSources(projectId: string): Observable<MapItem[]> {
    return this.apiHttp.get(`/roi/${projectId}/sources`).pipe(toMapItems);
  }

  public getQuestionnaireAvailableLead(projectId: string, options?: ListOptions): Observable<Lead> {
    return this.apiHttp
      .get<{ data: Lead }>(`/${this.endpoint}/${projectId}/lead/enqueue${this.getOptionsQuery(options)}`)
      .pipe(
        map((res) => {
          return res?.data;
        })
      );
  }

  public getMinDateForProjects(customerId: string, projectIds: string[]): Observable<string> {
    const options: ListOptions = {
      filters: [
        {
          field: 'customer_id',
          value: customerId,
          operator: FilterMatchMode.EQUALS,
        },
      ],
      parameters: [
        {
          field: 'project_ids',
          value: projectIds.join(','),
        },
      ],
    };

    return this.apiHttp.get(`/${this.endpoint}/min-project-date${this.getOptionsQuery(options)}`).pipe(responseData);
  }

  public transformFieldworkOptionsToQuery(options: ListOptions): string
  {
    const filters = options?.parameters?.map((filter) => {
      let optionFilter = { ...filter };

      if (
        (optionFilter.field === 'source_ids' || optionFilter.field === 'user_ids') &&
        Array.isArray(optionFilter.value)
      ) {
        optionFilter = { ...filter, value: filter.value.join(',') };
      }

      return optionFilter;
    });

    return this.getOptionsQuery({ parameters: filters || null });
  }

  public getFieldwork(projectId: string, options: ListOptions): Observable<Fieldwork> {
    const apiOptionsQuery = this.transformFieldworkOptionsToQuery(options);

    return this.apiHttp.get(`/${this.endpoint}/${projectId}/fieldwork${apiOptionsQuery}`).pipe(
      responseData,
      map((fieldwork) => {
        return {
          ...fieldwork,
          gross: {
            ...fieldwork.gross,
            statuses: fieldwork.gross.statuses.map(this.addDescriptionToFieldworkStatuses.bind(this)),
          },
          net: {
            ...fieldwork.net,
            statuses: fieldwork.net.statuses.map(this.addDescriptionToFieldworkStatuses.bind(this)),
          },
        };
      })
    );
  }

  public getProjectActionRequiredCount(projectId: string): Observable<number> {
    return this.apiHttp.get(`/${this.endpoint}/${projectId}/leads/action-required/count`).pipe(responseData);
  }

  private addDescriptionToFieldworkStatuses(status: FieldworkStatus): FieldworkStatus {
    return {
      ...status,
      description: this.translateService.instant(`market_discovery.fieldwork.status.${status.key}.description`),
      code: this.translateService.instant(`market_discovery.fieldwork.status.${status.key}.code`),
    };
  }

  private formatXyz(xyz: XyzResponse): XyzFormatedResult {
    let result: XyzTableRow[] = [];
    const generalFields = ['total', 'already_customer', 'wrong_profile', 'not_yet_reached', 'not_yet_reported'];

    result = generalFields.map((field) => {
      return {
        code: null,
        description: this.translateService.instant(`market_discovery.xyz.${field}.description`),
        amount: xyz.base[field],
        percentage: null,
        expected: null,
        mutated: xyz.mutations[field],
      };
    });

    result = [
      ...result,
      {
        code: null,
        description: this.translateService.instant(`market_discovery.xyz.reached.description`),
        amount: xyz.base.reached,
        percentage: xyz.base.reached_percentage,
        mutated: null,
        expected: null,
      },
    ];

    return {
      tableRows: [...result, ...this.getStatusRows(xyz), ...this.getOverallXyzRows(xyz)],
      graph: xyz.graph,
    };
  }

  private getStatusRows(xyz: XyzResponse): XyzTableRow[] {
    const fields = [
      {
        code: 'X',
        fields: ['x1', 'x2', 'x3'],
      },
      {
        code: 'Y',
        fields: ['y1', 'y2', 'y3'],
      },
      {
        code: 'Z',
        fields: ['z1', 'z2', 'z3'],
      },
    ];

    return fields.reduce((acc, field) => {
      return [
        ...acc,
        ...[
          {
            code: `Code ${field.code}`,
            description: this.translateService.instant(
              `market_discovery.xyz.code-${field.code.toLowerCase()}.description`
            ),
            amount: null,
            percentage: null,
            expected: null,
            tableRowStyleClass: 'bold',
          },
          ...field.fields.map((statusField) => {
            return {
              code: statusField.toUpperCase(),
              description: this.translateService.instant(`market_discovery.xyz.code-${statusField}.description`),
              amount: xyz.base[`total_${statusField}`],
              percentage: xyz.base[`percentage_${statusField}`],
              expected: xyz.base[`expected_${statusField}`],
              mutated: xyz.mutations[`total_${statusField}`],
            };
          }),
        ],
      ];
    }, []);
  }

  private getOverallXyzRows(xyz: XyzResponse): XyzTableRow[] {
    const tableRowStyleClass = 'no-borders light-blue bold';

    return [
      {
        code: null,
        description: this.translateService.instant(`market_discovery.xyz.percentage_short_term.description`),
        amount: null,
        percentage: xyz.base.percentage_short_term,
        expected: null,
        mutated: null,
        tableRowStyleClass,
      },
      {
        code: null,
        description: this.translateService.instant(`market_discovery.xyz.expected_short_term.description`),
        amount: null,
        percentage: null,
        mutated: null,
        expected: xyz.base.expected_short_term,
        tableRowStyleClass,
      },
      {
        code: null,
        description: this.translateService.instant(`market_discovery.xyz.percentage_long_term.description`),
        amount: null,
        percentage: xyz.base.percentage_long_term,
        mutated: null,
        expected: null,
        tableRowStyleClass,
      },
      {
        code: null,
        description: this.translateService.instant(`market_discovery.xyz.expected_long_term.description`),
        amount: null,
        percentage: null,
        mutated: null,
        expected: xyz.base.expected_long_term,
        tableRowStyleClass,
      },
    ];
  }

  private mapToRoiTableRow(
    roiData: RoiResponse[],
    tableRowProperties: any[],
    useCurrency: boolean
  ): Record<string, number | string | boolean>[] {
    return tableRowProperties.reduce((rows, key) => {
      const roiRow = {};

      if (
        !key.permissions ||
        (key.permissions &&
          key.permissions.some((permission) => {
            return !!this.ngxPermissionService.getPermission(permission);
          }))
      ) {
        roiData.forEach((roi) => {
          roiRow[roi.key] = +roi[key.field] || 0;
        });

        return [
          ...rows,
          {
            title: this.translateService.instant(
              `market_discovery.roi.${key.field}.${useCurrency && key.totalField ? 'value.' : ''}label`
            ),
            property: key.field,
            underlined: key.underlined,
            bold: key.bold,
            percentage: key.percentage,
            highlight: key.highlight,
            totalField: key.totalField,
            decimal: key.decimal,
            ...roiRow,
          },
        ];
      }

      return rows;
    }, []);
  }
}
