import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '@shared/services/auth.service';

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const targetEndpoint = '/api/auth/refresh-token';

    if (request.url.includes(targetEndpoint)) {
      const modifiedReq = request.clone({
        headers: request.headers.set('Authorization', `Bearer ${this.authService.getToken()}`),
      });

      return next.handle(modifiedReq);
    }

    return next.handle(request);
  }
}
