<app-dialog-layout>
  <ng-container content>
    <cpb-form-renderer
      [showHeader]="false"
      [formKey]="questionFormKey"
      [context]="{ questionnaire_id: questionnaireId, question_id: this.questionId }"
      [modelId]="questionId">
    </cpb-form-renderer>

    <div class="options-card">
      <cap-card
        *ngIf="questionOptionsShow$ | async"
        [title]="'market_discovery.questionnaire.question_options.label' | translate">
        <ng-template capTemplate="content">
          <div
            class="options-list"
            *ngIf="optionsList$ | async as optionsList"
            [class.divider]="optionsList?.length && !hideOptionsForm">
            <div class="options-option" *ngFor="let option of optionsList; let index = index">
              <div class="row no-gutters">
                <div class="option-label">
                  {{ 'market_discovery.questionnaire.question.option.label' | translate }} {{ index + 1 }}
                </div>

                <div class="option-value">
                  <div class="option-content">
                    <span>{{ option.value }}</span>
                    <h6>{{ option.description }}</h6>
                  </div>

                  <div class="actions">
                    <cap-row-action [actions]="rowActions" [item]="{option, index }"></cap-row-action>
                  </div>
                </div>
              </div>
            </div>
            <div class="buttons-wrapper" *ngIf="!hideNewOptionsForm && hideOptionsForm && optionsList.length">
              <cap-button
                [label]="'market_discovery.button.option.new' | translate"
                icon="fas fa-plus"
                styleClass="primary"
                (onClick)="addNewOption()">
              </cap-button>
            </div>
          </div>
          <div class="options-form" *ngIf="!hideOptionsForm">
            <cpb-form-renderer
              #optionsForm
              [formKey]="questionOptionsFormKey"
              [showHeader]="false"
              [defaultValue]="optionDefaultValue"
              [context]="{
                questionnaire_id: questionnaireId,
                question_id: questionId,
              }"
              [formConfiguration]="formConfiguration">
            </cpb-form-renderer>

            <div class="buttons">
              <cap-button
                [label]="'market_discovery.button.option.add' | translate"
                icon="fas fa-plus"
                styleClass="primary"
                *ngIf="isNewOption; else isEditOption"
                (onClick)="addOption()">
              </cap-button>

              <ng-template #isEditOption>
                <cap-button
                  [label]="'market_discovery.button.option.save' | translate"
                  icon="fas fa-floppy-disk"
                  styleClass="primary"
                  (onClick)="editOption()">
                </cap-button>
              </ng-template>
            </div>
          </div>
        </ng-template>
      </cap-card>
    </div>
  </ng-container>

  <ng-container buttons>
    <cap-button
      icon="fal fa-times"
      styleClass="secondary"
      [label]="'button.cancel' | translate"
      (onClick)="dialogRef.close()">
    </cap-button>

    <cap-button
      styleClass="primary"
      icon="fas fa-floppy-disk"
      [label]="'market_discovery.button.save' | translate"
      (onClick)="submit()">
    </cap-button>
  </ng-container>
</app-dialog-layout>
